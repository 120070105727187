<template>
  <span class="locale-select">
    <button
      class="uppercase"
      v-for="locale in availableLocaleOptions"
      :key="locale"
      :to="switchLocalePath(locale)"
      @click.capture="switchPath(locale)"
    >
      {{ t(`switch-locale-to.${locale}`) }}
    </button>
  </span>
</template>

<script lang="ts" setup>
import * as logger from '~/utils/logging'
const route = useRoute()
const { t, locale, availableLocales } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const canSwitchPath = (toLocale: string) => {
  // only applies to dynamic routes
  if (!(route.name as string).startsWith('slug')) return true

  const nuxtI18n: any = route.meta?.nuxtI18n
  return nuxtI18n && nuxtI18n[toLocale]
}

const switchPath = async (toLocale: string) => {
  const path = switchLocalePath(toLocale)
  const fromLocale = unref(locale)
  const isAvailable = canSwitchPath(toLocale)
  logger.log(
    `switching from "${fromLocale}" locale to "${toLocale}". translated path = "${path}"`,
    { route, isAvailable },
  )

  // @todo: should we pass through query params?
  await navigateTo(path)

  if (!canSwitchPath(toLocale)) {
    logger.error('[LocaleSelect]: translation not found', {
      fromLocale,
      toLocale,
      path,
    })

    throw createError({
      statusCode: 404,
      statusMessage: 'Page Not Found',
      cause: 'translation-not-found',
      message: 'This page is not available in the selected language',
      fatal: true,
    })
  }
}

const availableLocaleOptions = computed(() => {
  return availableLocales.filter((code) => code !== unref(locale))
})
</script>

<i18n lang="json">
{
  "en": {
    "switch-locale-to": {
      "en": "Inglés",
      "es": "Español"
    }
  },
  "es": {
    "switch-locale-to": {
      "en": "English",
      "es": "Español"
    }
  }
}
</i18n>
