<template>
  <nav v-if="menuItems.length">
    <h2 v-if="title" class="text-xl sr-only">{{ title }}</h2>
    <ul class="menu-list mx-0 px-0" :class="menuListClass">
      <li
        v-for="item in menuItems"
        :key="item.id"
        class="menu-item my-2.5 font-sans text-base list-none mx-0 px-0"
        :class="[
          menuItemClass,
          `menu-item-${item.slug}`,
          `menu-item-id-${item.id}`,
        ]"
      >
        <LinkTo
          class="menu-link no-underline text-gray-900"
          :class="`menu-link-${item.slug}`"
          :to="item.path"
        >
          <slot name="menuItem" :item="item">
            {{ item.label }}
          </slot>
        </LinkTo>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { dash } from 'radash'

const props = defineProps<{
  menuListClass?: string
  menuItemClass?: string
  title: string
  items: Array<{
    id: string
    label?: string
    name?: string
    title?: string
    path?: string
    url?: string
  }>
}>()

type _NavItemProp = (typeof props.items)[0]

type NavItem = {
  id: string
  label: string
  slug: string
  path: string
}

const convert = (item: _NavItemProp): NavItem => {
  const label = item.label || item.name || item.title
  const path = item.path || item.url || ''

  return {
    path,
    label: label || '',
    id: item.id,
    slug: dash(label || item.id),
  }
}

const menuItems = computed(() => (props.items || []).map(convert))
</script>

<style lang="postcss" scoped>
.nuxt-link-active {
  @apply font-bold;
}
.menu-link-all {
  @apply text-brand-turquoise;
}
.customs-menu a {
  @apply text-brand-peacock;
}
</style>
