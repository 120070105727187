<template>
  <section
    class="newsletter sm:grid sm:grid-cols-[8fr,4fr] sm:gap-x-4 items-center text-xl"
  >
    <div class="py-4 pb-0 sm:pb-4">
      <p class="inline-block font-sans text-xl text-brand-peacock">
        {{ t('newsletter-signup') }}
      </p>
    </div>
    <div class="py-4 pt-0 sm:pt-4">
      <LinkTo
        :to="t('signup-url')"
        class="bg-brand-moss border-brand-moss border-2 duration-500 ease-in-out font-medium font-sans inline-block leading-none no-underline px-6 py-2 rounded-full text-sm text-white tracking-widest transition text-center uppercase hover:bg-white hover:text-brand-moss"
      >
        {{ t('signup') }}
      </LinkTo>
    </div>
  </section>
</template>

<script lang="ts" setup>
const { t } = useI18n()
</script>

<i18n lang="json">
{
  "en": {
    "signup": "Sign Up",
    "newsletter-signup": "Sign up for Unity newsletters! Get Unity news, prayers, and resources straight to your inbox.",
    "signup-url": "https://shop.unity.org/e-news-sign-up"
  },
  "es": {
    "signup": "Suscríbete",
    "newsletter-signup": "¡Suscríbete a los boletines de Unity! Recibe noticias, oraciones y recursos de Unity directamente en tu bandeja de entrada.",
    "signup-url": "https://compra.unity.org/noticias-de-oracion"
  }
}
</i18n>
