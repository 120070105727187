<template>
  <nav
    id="main-menu"
    class="relative justify-between uppercase flex flex-row flex-wrap flex-grow"
    :aria-label="t('menu-name')"
  >
    <div
      class="nav-col nav-logo logo-wrapper flex-1 m-0 mt-1 lg:mt-3 justify-center"
    >
      <LinkTo :to="localizePath('/')" class="inline-block logo">
        <img
          class="mt-2.5 h-16 w-auto max-w-full lg:mt-0"
          src="/assets/logo-300px.png"
          height="139"
          width="300"
          alt="Unity Logo"
        />
      </LinkTo>
    </div>
    <div
      class="nav-col main-menu flex flex-row flex-wrap justify-around gap-2 py-6 -mx-4 whitespace-no-wrap sm:mx-0 md:py-0 lg:gap-4 lg:max-w-[600px] sm:flex-no-wrap lg:mt-3 lg:mx-8"
    >
      <LinkTo
        v-for="({ path, id }, idx) in items"
        :key="id"
        :to="localizePath(path)"
        class="flex flex-column flex-no-wrap justify-between m-0 py-0 sm:py-4 md:pt-4 md:pb-8 uppercase font-semibold tracking-widest text-lg sm:text-xl md:text-2xl text-neutral-500 transition-all duration-500 active:text-brand-moss hover:text-brand-moss"
      >
        <span
          class="nav-title relative inline-block md:py-2"
          :style="{ '--nav-subtitle': t(`${id}.subtitle`) }"
        >
          {{ t(`${id}.title`) }}
        </span>
      </LinkTo>
    </div>
    <div class="nav-col nav-donate flex-1 m-0 mt-2 lg:mt-4 justify-center">
      <LinkTo
        :to="t('donate.path')"
        class="donate flex-initial max-w-[18ch] mt-4 lg:mt-2 bg-brand-moss border-brand-moss border-2 duration-500 ease-in-out font-medium font-sans inline-block leading-none no-underline px-4 py-2 rounded-full text-sm text-white tracking-widest transition text-center uppercase hover:bg-white hover:text-brand-moss"
      >
        {{ t('donate.title') }}
      </LinkTo>
    </div>
  </nav>
</template>

<script lang="ts" setup>
const { t } = useI18n()
// @todo: update to use custom function
const localizePath = useLocalePath()

type MenuItem = {
  id: string
  title: string
  path: string
  subtitle: string
}

const items: Pick<MenuItem, 'id' | 'path'>[] = [
  { id: '82cf148b-7800-45c4-afa7-d8634d7b9e6e', path: '/' },
  { id: '5fd68342-3d9b-4201-92c6-1edb1adf7648', path: '/grow' },
  { id: 'dd18e973-c2ab-41d6-a1e8-d6cf7ed9c601', path: '/experience' },
]
</script>

<style lang="postcss" scoped>
/* This changes the order of the key components logo, menu, and donate button for smaller screens */
nav {
  & > div {
    order: 3;

    &:first-child {
      order: 1;
      text-align: left;
    }

    &:last-child {
      order: 2;
      text-align: right;
    }
  }

  @media (min-width: 1024px) {
    & > div,
    & > div:first-child,
    & > div:last-child {
      flex: 0 1 auto;
      order: unset;
    }
  }
}

/* Main Menu flex */
.main-menu {
  flex: 1 0 100%;

  @media screen(lg) {
    flex-basis: auto;
  }

  a {
    &.router-link-exact-active {
      @apply text-brand-moss; /* This sets the nav color for the active nav item */
    }
  }

  /*
      The pseudo elements and text are set here
      Lots of swapping positions to work with the screen realestate
  
      Let me if the text below should be set in the DOM ... I'm guessing so inorder to be translated
  
    */
  @media screen(md) {
    a > .nav-title::after {
      @apply absolute font-serif font-medium h-0 inline-block normal-case opacity-0 overflow-hidden text-center text-neutral-500 text-base italic tracking-normal;

      content: var(--nav-subtitle);
      top: 100%;
      left: 50%;
      right: auto;
      transform: translate(-50%, 0);
      transition: all 0.5s;
      white-space: nowrap;
    }

    &:first-child > .nav-title::after {
      @media screen(lg) {
        left: 0;
        right: auto;
        transform: translate(0, 0);
      }
    }
    &:last-child > .nav-title::after {
      transform: translate(-50%, 0);
    }
  }
  @media screen(lg) {
    a:first-child > .nav-title::after {
      left: 0;
      right: auto;
      transform: translate(0, 0);
    }
    a:last-child > .nav-title::after {
      left: auto;
      right: 0;
      transform: translate(0, 0);
    }
  }

  @media screen(xl) {
    a:first-child > .nav-title::after {
      left: 50%;
      right: auto;
      transform: translate(-50%, 0);
    }
    a:last-child > .nav-title::after {
      left: 50%;
      right: auto;
      transform: translate(-50%, 0);
    }
  }

  /* show the nav descriptions on hover and if active */
  a:hover > .nav-title::after,
  a.router-link-exact-active > .nav-title::after {
    @apply text-brand-moss;

    height: 2rem;
    opacity: 1;
  }

  /* hide the other descriptions if the user is hovering over other nav items than the currently active nav item */
  &:hover a:not(:hover) > .nav-title::after {
    height: 0;
    opacity: 1;
  }
}
</style>

<i18n lang="json">
{
  "en": {
    "menu-name": "Main menu",
    "donate": {
      "title": "Support Our Mission",
      "path": "/static/thank-you-supporting-our-work"
    },
    "82cf148b-7800-45c4-afa7-d8634d7b9e6e": {
      "title": "Discover",
      "subtitle": "\"Feed your mind, body, spirit\""
    },
    "5fd68342-3d9b-4201-92c6-1edb1adf7648": {
      "title": "Grow",
      "subtitle": "\"Find meaningful tools and resources\""
    },
    "dd18e973-c2ab-41d6-a1e8-d6cf7ed9c601": {
      "title": "Experience",
      "subtitle": "\"Explore sacred places and activities\""
    }
  },
  "es": {
    "menu-name": "Menú principal",
    "donate": {
      "title": "Apoya a Unity",
      "path": "/static/gracias-por-apoyar-nuestra-obra"
    },
    "82cf148b-7800-45c4-afa7-d8634d7b9e6e": {
      "title": "Descubrir",
      "subtitle": "\"Nutre tu mente, cuerpo y espíritu\""
    },
    "5fd68342-3d9b-4201-92c6-1edb1adf7648": {
      "title": "Crecer",
      "subtitle": "\"Encuentra herramientas y recursos edificantes\""
    },
    "dd18e973-c2ab-41d6-a1e8-d6cf7ed9c601": {
      "title": "Experiencia",
      "subtitle": "\"Explora actividades y lugares sagrados\""
    }
  }
}
</i18n>
