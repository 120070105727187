<template>
  <nav
    id="top-menu"
    class="flex flex-row flex-nowrap justify-end items-center py-1"
    :aria-label="t('menu-name')"
  >
    <LinkTo :to="localizePath('/search')" class="nav-link mr-6">
      <span class="sr-only">{{ t('search') }}</span>
      <Icon class="icon" name="fa6-solid:magnifying-glass" />
    </LinkTo>
    <LinkTo :to="localizePath('/shop')" class="nav-link mx-4 text-sm uppercase">
      {{ t('shop') }}
    </LinkTo>
    <UnityLoginLinks class="mx-4 text-sm uppercase" />
    <UnityLocaleSelect class="mx-4 text-sm uppercase" />
  </nav>
</template>

<script lang="ts" setup>
const { t } = useI18n()
// @todo: update to use custom function
const localizePath = useLocalePath()
</script>

<i18n lang="json">
{
  "en": {
    "menu-name": "Top menu",
    "search": "Search",
    "shop": "Shop"
  },
  "es": {
    "menu-name": "Menú superior",
    "search": "Buscar",
    "shop": "Tienda"
  }
}
</i18n>
