<template>
  <button
    class="scroll-to-top absolute top-[100vh] right-0 bottom-[-5em] pointer-events-none"
    :aria-label="t('Go to top of the page')"
    @click="scrollToTop"
  >
    <div v-if="isVisible" class="scroll-to-top-link">
      <div class="inline-flex justify-start items-center flex-nowrap gap-2">
        <span class="inline-block pr-2 uppercase">{{ t('Top') }}</span>
        <Icon
          class="scroll-to-top-icon inline-block"
          name="fa6-solid:angle-up"
        />
      </div>
    </div>
  </button>
</template>

<script lang="ts" setup>
const { t } = useI18n()
const { y } = useWindowScroll()
const { height } = useWindowSize()

const isVisible = computed(() => {
  const pos = unref(y)
  const docHeight = unref(height)

  // show link when 80% of page is scrolled
  return pos && docHeight && docHeight * 0.8 < pos
})

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}
</script>

<style lang="postcss" scoped>
.scroll-to-top-link {
  @apply inline-block fixed bottom-[5%] right-0 pl-4 pr-4 py-2;
  @apply text-xs uppercase text-brand-peacock bg-brand-sky hover:bg-primary-300 pointer-events-auto;
  @apply transition-transform duration-100 ease-in hover:scale-110 focus:scale-110 focus:outline-none;
}
</style>

<i18n lang="json">
{
  "en": {
    "Top": "Top",
    "Go to top of the page": "Go to top of the page"
  },
  "es": {
    "Top": "Arriba",
    "Go to top of the page": "Ir a la parte superior de la página"
  }
}
</i18n>
