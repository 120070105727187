// Generated by nuxt-prepare
export const aboutUsMenu = {
  "en": [
    {
      "label": "About Us",
      "slug": "about-us",
      "id": "66a4864c-2c9e-48c2-94c9-bd77107a5744",
      "path": "/en/static/about-us"
    },
    {
      "label": "Experience",
      "slug": "experience",
      "id": "dd18e973-c2ab-41d6-a1e8-d6cf7ed9c601",
      "path": "/en/experience#join-us"
    }
  ],
  "es": [
    {
      "label": "Sobre nosotros",
      "slug": "about-us",
      "id": "66a4864c-2c9e-48c2-94c9-bd77107a5744",
      "path": "/es/static/sobre-nosotros"
    },
    {
      "label": "Experiencia",
      "slug": "experience",
      "id": "dd18e973-c2ab-41d6-a1e8-d6cf7ed9c601",
      "path": "/es/experiencia#join-us"
    }
  ]
}
export type AboutUsMenu = typeof aboutUsMenu
export const contactUsMenu = {
  "en": [
    {
      "label": "Contact Unity World Headquarters",
      "slug": "contact-unity-world-headquarters",
      "id": "5c26e27b-6afd-40a2-b58f-b5b12ef8ec96",
      "path": "/en/static/contact-unity-world-headquarters"
    }
  ],
  "es": [
    {
      "label": "Contáctanos",
      "slug": "contact-unity-world-headquarters",
      "id": "5c26e27b-6afd-40a2-b58f-b5b12ef8ec96",
      "path": "/es/static/contactanos"
    }
  ]
}
export type ContactUsMenu = typeof contactUsMenu
export const privacyMenu = {
  "en": [
    {
      "label": "Terms of Use",
      "slug": "terms-of-use",
      "id": "efccf7b4-7a9b-41f2-b048-05afbd1ba3c9",
      "path": "/en/static/terms-use"
    },
    {
      "label": "Privacy Policy",
      "slug": "privacy-policy",
      "id": "0aa45650-112a-4bf2-98a3-05b0d2fe83fe",
      "path": "/en/static/privacy-policy"
    }
  ],
  "es": [
    {
      "label": "Condiciones de uso y compra de productos",
      "slug": "terms-of-use",
      "id": "efccf7b4-7a9b-41f2-b048-05afbd1ba3c9",
      "path": "/es/static/condiciones-de-uso-y-compra-de-productos"
    },
    {
      "label": "POLÍTICA DE PRIVACIDAD",
      "slug": "privacy-policy",
      "id": "0aa45650-112a-4bf2-98a3-05b0d2fe83fe",
      "path": "/es/static/politica-de-privacidad"
    }
  ]
}
export type PrivacyMenu = typeof privacyMenu
export const topicStaticMenu = {
  "en": [
    {
      "id": "all",
      "slug": "all",
      "label": "",
      "path": "/"
    },
    {
      "label": "Welcome to the Unity Prayer Ministry",
      "slug": "welcome-to-the-unity-prayer-ministry",
      "id": "a50f4ebe-d38b-4180-8333-b627bc92cefd",
      "path": "/en/static/welcome-unity-prayer-ministry"
    },
    {
      "id": "daily-word-current-issue",
      "label": "Daily Word Magazine",
      "slug": "daily-word-current-issue",
      "path": "/goto/daily-word/current-issue"
    },
    {
      "id": "spirituality-and-health-current-issue",
      "label": "Spirituality & Health",
      "slug": "spirituality-and-health-current-issue",
      "path": "/goto/spirituality-and-health/current-issue"
    },
    {
      "label": "Unity Digital Archive and Museum",
      "slug": "unity-digital-archive-and-museum",
      "id": "9d87e25e-25a9-4bc4-a64e-cb4294b3c2f7",
      "path": "/en/static/unity-digital-archive-and-museum"
    },
    {
      "label": "Retreats and Events",
      "slug": "retreats-and-events",
      "id": "59ebfb26-78d7-4a8a-bb9f-c9da45f89589",
      "path": "/en/events"
    },
    {
      "label": "Booklets from Unity",
      "slug": "booklets-from-unity",
      "id": "c94ba6dd-8f64-4639-bb1e-015232462177",
      "path": "/en/collection/booklets-unity"
    },
    {
      "id": "7776bd76-3567-455c-af45-21c1bf4e155f",
      "label": "",
      "slug": "bible-interpretations",
      "path": "/bible-interpretations"
    },
    {
      "label": "Unity Social Media",
      "slug": "unity-social-media",
      "id": "b2af2f6c-c348-4857-8881-f48b4ee033e7",
      "path": "/en/static/unity-social-media"
    },
    {
      "id": "10ac1c2b-dc62-487a-be73-e53d6b674764",
      "label": "",
      "slug": "find-a-unity-community",
      "path": "https://www.unityuwm.org/map-ministries"
    },
    {
      "label": "About Us",
      "slug": "about-us",
      "id": "66a4864c-2c9e-48c2-94c9-bd77107a5744",
      "path": "/en/static/about-us"
    },
    {
      "label": "Experience",
      "slug": "experience",
      "id": "dd18e973-c2ab-41d6-a1e8-d6cf7ed9c601",
      "path": "/en/experience#join-us"
    }
  ],
  "es": [
    {
      "id": "all",
      "slug": "all",
      "label": "",
      "path": "/"
    },
    {
      "label": "Bienvenido al Ministerio de Oración de Unity",
      "slug": "welcome-to-the-unity-prayer-ministry",
      "id": "a50f4ebe-d38b-4180-8333-b627bc92cefd",
      "path": "/es/static/bienvenido-al-ministerio-de-oracion-de-unity"
    },
    {
      "id": "daily-word-current-issue",
      "label": "La Palabra Diaria",
      "slug": "la-palabra-diaria-current-issue",
      "path": "/goto/la-palabra-diaria/current-issue"
    },
    {
      "label": "Folletos de Unity",
      "slug": "booklets-from-unity",
      "id": "c94ba6dd-8f64-4639-bb1e-015232462177",
      "path": "/es/coleccion/folletos-de-unity"
    },
    {
      "label": "Medios sociales de Unity",
      "slug": "unity-social-media",
      "id": "b2af2f6c-c348-4857-8881-f48b4ee033e7",
      "path": "/es/static/medios-sociales-de-unity"
    },
    {
      "label": "Sobre nosotros",
      "slug": "about-us",
      "id": "66a4864c-2c9e-48c2-94c9-bd77107a5744",
      "path": "/es/static/sobre-nosotros"
    },
    {
      "label": "Experiencia",
      "slug": "experience",
      "id": "dd18e973-c2ab-41d6-a1e8-d6cf7ed9c601",
      "path": "/es/experiencia#join-us"
    }
  ]
}
export type TopicStaticMenu = typeof topicStaticMenu
export const topicsMenu = {
  "en": [
    {
      "id": "all",
      "slug": "all",
      "label": "",
      "path": "/"
    },
    {
      "id": "0472ba03-6a53-4b34-a5d6-6a6fa4ecf6de",
      "label": "Black Communities",
      "slug": "black-communities",
      "path": "/en/topic/black-communities"
    },
    {
      "id": "4713a568-ec19-48f2-a7d2-60d976f8e61f",
      "label": "LGBTQIA+ Communities",
      "slug": "lgbtqia+-communities",
      "path": "/en/topic/lgbtqia-communities"
    },
    {
      "id": "00432b85-8300-4be9-84ad-d7d601ae9ca9",
      "label": "Grief",
      "slug": "grief",
      "path": "/en/topic/grief"
    },
    {
      "id": "a32833de-2602-4ddb-ab2f-68842cad9a7c",
      "label": "Love and Relationships",
      "slug": "love-and-relationships",
      "path": "/en/topic/love-and-relationships"
    },
    {
      "id": "226a8345-3cb3-4471-a08b-42c8347364b5",
      "label": "Gratitude",
      "slug": "gratitude",
      "path": "/en/topic/gratitude"
    },
    {
      "id": "d44f4b9a-1077-432a-ae03-c65eeebde611",
      "label": "Guidance and Faith",
      "slug": "guidance-and-faith",
      "path": "/en/topic/guidance-and-faith"
    },
    {
      "id": "e0188e7b-48b6-473e-92d6-b6e38cf010da",
      "label": "Healing",
      "slug": "healing",
      "path": "/en/topic/healing"
    },
    {
      "id": "208be9e6-cc79-48dd-a120-8abb2e10cf52",
      "label": "Inner Peace",
      "slug": "inner-peace",
      "path": "/en/topic/inner-peace"
    },
    {
      "id": "cb4e2841-3fe6-4a73-a11c-6a47afc59733",
      "label": "Inspiration",
      "slug": "inspiration",
      "path": "/en/topic/inspiration"
    },
    {
      "id": "f2a40789-4319-44f9-a8b3-7afafa7c2697",
      "label": "Prayer and Meditation",
      "slug": "prayer-and-meditation",
      "path": "/en/topic/prayer-and-meditation"
    },
    {
      "id": "fa0ea4f4-bfcc-4cad-b167-d72af801617f",
      "label": "Overcoming Fear",
      "slug": "overcoming-fear",
      "path": "/en/topic/overcoming-fear"
    },
    {
      "id": "fb5bb90c-9637-4316-9309-b6e83dae810f",
      "label": "World Peace",
      "slug": "world-peace",
      "path": "/en/topic/world-peace"
    },
    {
      "id": "a03aeb04-c88f-4d56-964b-3cb3f8f3d35e",
      "label": "Prosperity",
      "slug": "prosperity",
      "path": "/en/topic/prosperity"
    },
    {
      "id": "4b6f6a98-12fb-494d-b8ee-aedefdbd75ed",
      "label": "Vision, Create, Attract",
      "slug": "vision,-create,-attract",
      "path": "/en/topic/vision-create-attract"
    }
  ],
  "es": [
    {
      "id": "all",
      "slug": "all",
      "label": "",
      "path": "/"
    },
    {
      "id": "4713a568-ec19-48f2-a7d2-60d976f8e61f",
      "label": "Comunidades LGBTQIA+",
      "slug": "lgbtqia+-communities",
      "path": "/es/topic/comunidades-lgbtqia"
    },
    {
      "id": "00432b85-8300-4be9-84ad-d7d601ae9ca9",
      "label": "Duelo",
      "slug": "grief",
      "path": "/es/topic/duelo"
    },
    {
      "id": "a32833de-2602-4ddb-ab2f-68842cad9a7c",
      "label": "El amor y las interacciones con los demás",
      "slug": "love-and-relationships",
      "path": "/es/topic/el-amor-y-las-interacciones-con-los-demas"
    },
    {
      "id": "226a8345-3cb3-4471-a08b-42c8347364b5",
      "label": "Gratitud",
      "slug": "gratitude",
      "path": "/es/topic/gratitud"
    },
    {
      "id": "d44f4b9a-1077-432a-ae03-c65eeebde611",
      "label": "Guía y fe",
      "slug": "guidance-and-faith",
      "path": "/es/topic/guia-y-fe"
    },
    {
      "id": "e0188e7b-48b6-473e-92d6-b6e38cf010da",
      "label": "Sanación",
      "slug": "healing",
      "path": "/es/topic/sanacion"
    },
    {
      "id": "208be9e6-cc79-48dd-a120-8abb2e10cf52",
      "label": "Paz interna",
      "slug": "inner-peace",
      "path": "/es/topic/paz-interna"
    },
    {
      "id": "cb4e2841-3fe6-4a73-a11c-6a47afc59733",
      "label": "Inspiración",
      "slug": "inspiration",
      "path": "/es/topic/inspiracion"
    },
    {
      "id": "f2a40789-4319-44f9-a8b3-7afafa7c2697",
      "label": "Oración y meditación",
      "slug": "prayer-and-meditation",
      "path": "/es/topic/oracion-y-meditacion"
    },
    {
      "id": "fa0ea4f4-bfcc-4cad-b167-d72af801617f",
      "label": "Superando el miedo",
      "slug": "overcoming-fear",
      "path": "/es/topic/superando-el-miedo"
    },
    {
      "id": "fb5bb90c-9637-4316-9309-b6e83dae810f",
      "label": "Paz mundial",
      "slug": "world-peace",
      "path": "/es/topic/paz-mundial"
    },
    {
      "id": "a03aeb04-c88f-4d56-964b-3cb3f8f3d35e",
      "label": "Prosperidad",
      "slug": "prosperity",
      "path": "/es/topic/prosperidad"
    },
    {
      "id": "4b6f6a98-12fb-494d-b8ee-aedefdbd75ed",
      "label": "Visualiza, Crea, Atrae",
      "slug": "vision,-create,-attract",
      "path": "/es/topic/visualiza-crea-atrae"
    }
  ]
}
export type TopicsMenu = typeof topicsMenu
export const socialMenu = {
  "en": [
    {
      "id": "facebook",
      "label": "Find us on Facebook",
      "slug": "facebook",
      "path": "https://www.facebook.com/beunity"
    },
    {
      "id": "instagram",
      "label": "Find us on Instagram",
      "slug": "instagram",
      "path": "https://www.instagram.com/unity4today/"
    },
    {
      "id": "linked-in",
      "label": "Follow us on LinkedIn",
      "slug": "linked-in",
      "path": "https://www.linkedin.com/company/unity-world-headquarters-at-unity-village/"
    },
    {
      "id": "youtube",
      "label": "Follow us on Youtube",
      "slug": "youtube",
      "path": "https://www.youtube.com/UnityOnlineOrg"
    }
  ],
  "es": [
    {
      "id": "facebook",
      "label": "Encontrarnos en Facebook",
      "slug": "facebook",
      "path": "https://www.facebook.com/beunityenespanol"
    },
    {
      "id": "instagram",
      "label": "Encontrarnos en Instagram",
      "slug": "instagram",
      "path": "https://www.instagram.com/Lapalabradiariaunity/"
    },
    {
      "id": "youtube",
      "label": "Síganos en YouTube",
      "slug": "youtube",
      "path": "https://www.youtube.com/channel/UC6dOnlBQutpqNcOXToyN71Q"
    }
  ]
}
export type SocialMenu = typeof socialMenu
